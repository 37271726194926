.header {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  .logo-container {
    height: 100%;
    width: 60px;
    padding: 15px 0px;
  }

  .options {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .option {
      padding: 10px 15px;
      cursor: pointer;

      &:hover {
        transform: scale(1.02);
        transition: transform 0.1s;
      }
    }
  }
  .photo-container {
    width: 42px;
  }
  .empty-container {
    width: 52px;
  }
  .account-photo {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 50%;
    border: 0.1px solid lightgray;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }
}
