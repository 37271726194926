.cart-icon {
  width: 45px;
  height: 45px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0px 0px 6px 2px;
  color: #444444;

  .shopping-icon {
    width: 24px;
    height: 24px;
  }

  .item-count {
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    color: #444444;
    bottom: 12px;
  }
}
