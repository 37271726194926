.social-media-logo {
  &:hover {
    opacity: 0.8;
  }
}
.github-logo {
  margin: 0px 25px 0px 0px;
}

.about-me {
  margin-top: 30px;
  width: 130px;
}

.mailto {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
